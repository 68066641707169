import React from "react";
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import http from "../../../http";



const Filter = ({sendDataToParent,sendRequestedCourseToParent,coursePopId,cityPopId }) => {
	
	const [cities, setCities] = useState([]);
	const [cityId, setCityId] = useState();
	const [courses, setCourses] = useState([]);
	const [courseId, setCourseId] = useState();
	const [requestType,setRequestType]=useState();
	const [btnLoading, setBtnLoading] = useState(false);
	const { type } = useParams();
	

	useEffect(() => {
		fetchCity();
		fetchCourses();
		console.log(type)
		if(type=='all'){
			getMentors()	
		}
	}, [])
	useEffect(() => {
		setParam();
		
	}, [coursePopId,cityPopId])
	const setParam = async () => {
		setCourseId(coursePopId)
	    setCityId(cityPopId)
		console.log(coursePopId)
		console.log(cityPopId)
	 };
	const fetchCourses = async () => {
		try {
		  const res = await http.get('/courses');
		  const response = res; // Response received
		  setCourses(response.data)
		} catch (error) {
		 
		  console.error(error);
		}
	  };
	const fetchCity = async () => {
		try {
			const res = await http.get('/college-city');
			const response = res; // Response received
			setCities(response.data)
		} catch (error) {
			console.error(error);
		}
	};
	const handleRequestType=(e)=>{
		setRequestType(e.target.value)
		console.log('filter' + e.target.value)
	}
	const handleCity = (e) => {
		setCityId(e.target.value)
	}
	const handleCourse = (e) => {
		setCourseId(e.target.value)
	}
	const submitFinder = async (e) => {
		e.preventDefault();
		setBtnLoading(true);
		if(requestType==='mentor'){
			var urlName='/get-experts';
		  }else if(requestType==='college'){
			var urlName='/get-colleges';
		  }else{
			var urlName='/get-experts';
		  }
		await http.post(urlName, { type: requestType, course: courseId, city: cityId })
			.then(response => {
				console.log(response)
				sendDataToParent(response.data);
				sendRequestedCourseToParent(courseId);
				setBtnLoading(false);
				
			})
			.catch(e => {
				setBtnLoading(false);
				console.log(e);
			});
	}
	const getMentors = async () => {
		
		setBtnLoading(true);
		var urlName='/get-experts';
		await http.post(urlName, { type: 'mentor', course: courseId, city: cityId })
			.then(response => {
				
				sendDataToParent(response.data);
				sendRequestedCourseToParent(courseId);
				setBtnLoading(false);
				
			})
			.catch(e => {
				setBtnLoading(false);
				console.log(e);
			});
	}
	return (
		<>
			<form>
				<div className="mt-2 mb-2 text-center">
					
					<div className="col-lg-12 mb-4 mt-4">
					<select style={{width:'100px'}} value={requestType || ''} onChange={(e) => handleRequestType(e)}  className="courses_search_select courses_search_input">
							<option value={'mentor'}>Mentor</option>
							<option value={'college'}>College</option>
							
						</select>
					<select style={{width:'100px'}} value={courseId || ''} onChange={(e) => handleCourse(e)}  className="courses_search_select courses_search_input ms-3">
							<option value="0">Course</option>
							{
								courses ? courses.map((item, index) => (
									<option key={index} value={item.id}>{item.name}</option>
								))
									: ''
							}
						</select>
						<select style={{width:'150px'}} value={cityId || ''}  onChange={(e) => handleCity(e)} className="courses_search_select courses_search_input ms-3">
							<option value="0">City</option>
							{
								cities ? cities.map((city, index) => (
									<option key={index} value={city.id}>{city.name}</option>
								))
									: ''
							}

						</select>
						{
							btnLoading ? <>
								<button class="btn btn-primary btn-lg" type="button" disabled>
									<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
									Loading...
								</button>
							</> : <>
								<button type="submit" className="btn btn-primary ms-3 btn-lg" onClick={submitFinder}>Submit</button>
							</>
						}


					</div>
				</div>


			</form>




		</>

	)
}

export default Filter;