import React from 'react'
import { useState,useEffect } from 'react'


import http from '../../../http'
import TopmentorItem from './TopmentorItem';


export default function Topmentors() {
 
    const [topmentors, setTopmentor] = useState([]);
   
    useEffect(() => {
        getTopMentors();
       }, [])
    
  
  
const getTopMentors = async () => {
        try {
            const res = await http.get('/top-mentor');
            const response = res; // Response received
            setTopmentor(response.data);
            
        } catch (error) {
            console.error(error);
        }
    };
  return (
    <>
    
   
      <section className="features-1  secWrap">
      <div className="py-5">
        <div className="container">
            
            <div className="row g-4 justify-content-center">
            <div className='col-sm-12 mb-5 text-center'>
               <h3 className="mb-0 sec-title-txt">Right Guidance from Real Students</h3>
               <p class="text-center mt-3 wt-6">College Dwar has a team of 500+ real college students who are there to assist you  and 
               give you the right guidance <br /> for your successfull career ahead.</p>
            </div>
            {
                topmentors?topmentors.map((item)=>(
                                    <TopmentorItem item={item} />
                                ))
                                :
                                ''
                            }
             
            </div>
        </div>
    </div>
    </section>
    </>
  )
}
