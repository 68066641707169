import React from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { useState, useEffect } from 'react'
import http from '../../../http'
 


export default function AddCollage() {

    const [input, setInput] = useState({});
    const [countries, setCountry] = useState([]);
    const [countryId, setCountryId] = useState('');
    const [states, setState] = useState([]);
    const [stateId, setStateId] = useState([]);
    const [cities, setCity] = useState([]);
    const [messages, setMessages] = useState();
    const [btnLoading, setBtnLoading] = useState(false);

    useEffect(() => {

        document.title = "Add Collage";
        fetchCountries();
    }, [])

    const fetchCountries = async () => {
        await http.get('/get-country')
            .then(response => {
                setCountry(response.data.data)
            })
            .catch(e => {
                console.log(e);
            });
    }
    useEffect(() => {
        const getstate = async () => {
            await http.post('/get-state', { country_id: countryId })
                .then(response => {
                    setState(response.data.data)
                })
                .catch(e => {
                    console.log(e);
                });
        }
        getstate();

    }, [countryId]);
    useEffect(() => {
        const getCity = async () => {
            await http.post('/get-city', { state_id: stateId })
                .then(response => {
                    setCity(response.data.data)
                })
                .catch(e => {
                    console.log(e);
                });
        }
        getCity();

    }, [stateId]);
    const handlecountry = (e) => {
        const getcoutryid = e.target.value;
        setCountryId(getcoutryid);
        const { name, value } = e.target
        setInput(values => ({ ...values, [name]: value }))
    }
    const handleState = (e) => {
        const getstateid = e.target.value;
        setStateId(getstateid);
        const { name, value } = e.target
        setInput(values => ({ ...values, [name]: value }))
    }
    
    const handleChange = (e) => {

        const { name, value } = e.target
        if (name === 'pic') {
            setInput(values => ({
                ...values,
                [name]: e.target.files[0],
            }))
        } else setInput(values => ({ ...values, [name]: value }));

    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setBtnLoading(true); // Set pageLoading before sending API request
            const res = await http.post('/add-collage/process',input)
            const response = res; // Response received
            toast.success("College has been successfully created !")
            setBtnLoading(false);
            
        } catch (error) {
            setBtnLoading(false); // Stop pageLoading in case of error
            toast.error("Something went wrong !")
            console.error(error);
        }
    };

    return (
        <div>

            <br></br>
            <ToastContainer  />
            {messages &&

                <div className="alert alert-primary" role="alert">
                    {messages}
                </div>

            }

            <div style={{ border: "2px solid black", padding: "50px" }}>

                <h2>Add Collage</h2>

                <br></br>
                <div className="row mb-3">
                <div className="form-group col-lg-3 mb-2">
                    <label htmlFor="name">Name</label>
                    <input type="text" className="form-control" id="name" name="name" value={input.name || ''} onChange={handleChange} placeholder="Enter name" />
                </div>
                <div className="form-group col-lg-3  mb-2">
                    <label htmlFor="name">Email</label>
                    <input type="email" className="form-control" id="email" name="email" value={input.email || ''} onChange={handleChange} placeholder="Enter email" />
                </div>
                <div className="form-group col-lg-3 mb-2">
                    <label htmlFor="name">Phone</label>
                    <input type="number" className="form-control" id="phone" name="phone" value={input.phone || ''} onChange={handleChange} placeholder="Enter phone" />
                </div>
                <div className="form-group col-lg-3 mb-2">
                    <label htmlFor="name">Photo</label>
                    <input
                        type="file"
                        className="form-control"
                        label="Image"
                        name="pic"
                        onChange={handleChange}

                    />
                
               </div>
                    <div className="form-group col-md-3 mb-4">
                        <label className="mb-2">Country</label>
                        <select name="country" className="form-control" onChange={(e) => handlecountry(e)}>
                            <option>--Select Country--</option>
                            {
                                countries.map((getcon) => (
                                    <option key={getcon.id} value={getcon.id}> {getcon.name}</option>
                                ))
                            }

                        </select>
                    </div>
                    <div className="form-group col-md-3">
                        <label className="mb-2">State</label>
                        <select name="state" className="form-control" onChange={ (e)=>handleState(e)}>
                            <option>--Select State--</option>
                            {
                                states.map((st, index) => (
                                    <option key={index} value={st.id}>{st.name}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="form-group col-md-3">
                        <label className="mb-2">City</label>
                        <select name="city" className="form-control" onChange={(e)=>handleChange(e)}>
                            <option>--Select City--</option>
                            {
                                cities.map((city, index) => (
                                    <option key={index} value={city.id}>{city.name}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="form-group col-lg-3 mb-2">
                    <label htmlFor="name">Pin Code</label>
                    <input type="number" className="form-control" id="pin" name="zip" value={input.zip || ''} onChange={handleChange} placeholder="Enter pin code" />
                </div>
                <div className="form-group col-lg-3 mb-4">
                    <label htmlFor="name">Average CTC</label>
                    <input type="text" className="form-control" id="average_ctc" name="average_ctc" value={input.average_ctc || ''} onChange={handleChange} placeholder="Enter average_ctc" />
                </div>
                <div className="form-group col-lg-3 mb-4">
                    <label htmlFor="name">Highest CTC</label>
                    <input type="text" className="form-control" id="highest_ctc" name="highest_ctc" value={input.highest_ctc || ''} onChange={handleChange} placeholder="Enter highest_ctc" />
                </div>
                <div className="form-group col-lg-3 mb-4">
                    <label htmlFor="name">Fees</label>
                    <input type="text" className="form-control" id="fees" name="fees" value={input.fees || ''} onChange={handleChange} placeholder="Enter fees" />
                </div>
                <div className="form-group col-lg-6 mb-4">
                    <label htmlFor="name">About College</label>
                    <textarea rows="5" cols="52" className="form-control" id="description" name="description" value={input.description || ''} onChange={handleChange} placeholder="Enter description" ></textarea>
                </div>
               </div>
                <br></br>
                {
            btnLoading ? <>
            <button class="btn btn-primary" type="button" disabled>
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Loading...
          </button>
          </> : <>
          <button type="submit" onClick={handleSubmit} className="btn btn-primary form-submit">Submit</button>
          </>
          }
               


            </div>

        </div>
    )
}
