import React from 'react'
import { useState,useEffect } from 'react'
import { Link } from 'react-router-dom'
import http from '../../../http'

export default function Applicant() {
  const [users, setUsers] = useState([])
  const [pageLoading, setPageLoading] = useState(false);
    useEffect(() => {
        document.title = "Requested List";
        fetchApplicants();
    }, [])

    const fetchApplicants = () => {
        setPageLoading(true); 
        http.post('/applicant-list',{type:'all'})
            .then(response => {
                setUsers(response.data)
                setPageLoading(false);
            })
            .catch(e => {
                console.log(e);
                setPageLoading(false);
            });
    }

    const handleDelete = (id) => {
        http.delete('/applicant-delete/'+id)
            .then(response => {
                fetchApplicants();
            })
            .catch(e => {
                console.log(e);
            });
    }


  return (
    <div>
    
        {pageLoading ? <><div className="loader"></div></> : ''}
        
        <h2>Applicant List</h2>
        
        <br></br>
        <table className="table table-striped">
            <thead>
                <tr>
                    <th>Name</th>
                     <th>Contact</th>
                     <th>Applied Course</th>
                    
                     <th>Applied To</th>
                     <th>Date</th>
                     <th>Delete</th>
                </tr>
            </thead>
            <tbody>
                {users && users.map((user, index) => (
                    <tr key={index}>
                        <td>{user.name}</td>
                        <td>{user.phone}</td>
                        <td>{user.course}</td>
                        <td>
                          <div>{user.mentor?user.mentor:user.college}</div>
                          <div className='text-secondary-color'>{user.requested_type}</div> 
                        </td>
                        
                        <td>
                             {user.requested_dt}
                       
                        </td>
                       <td>
                           
                           <button onClick={()=>{handleDelete(user.id)}} className="btn btn-danger btn-sm" >Delete</button>
                        </td>

                    </tr>
                ))}
            </tbody>
        </table>
      
    </div>
  )
}
