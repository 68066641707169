import React from 'react'
import { useState,useEffect } from 'react'
import { Link } from 'react-router-dom'
import http from '../../../http'

export default function ApplicantOther() {
  const [users, setUsers] = useState([])
  const [pageLoading, setPageLoading] = useState(false);
    useEffect(() => {
        document.title = "Requested List";
        fetchApplicants();
    }, [])

  
const fetchApplicants = async () => {
        try {
            setPageLoading(true); 
            const res = await http.get('/applicant-otherlist');
            const response = res; // Response received
            setUsers(response.data)
                setPageLoading(false);
            
        } catch (error) {
            console.log(error);
            setPageLoading(false);
        }
    };
    const handleDelete = (id) => {
        http.delete('/applicant-delete/'+id)
            .then(response => {
                fetchApplicants();
            })
            .catch(e => {
                console.log(e);
            });
    }


  return (
    <div>
    
        {pageLoading ? <><div className="loader"></div></> : ''}
        
        <h2>Other Applicant List</h2>
        
        <br></br>
        <table className="table table-striped">
            <thead>
                <tr>
                    <th>Name</th>
                     <th>Email</th>
                     <th>Phone</th>
                    
                     <th>Message</th>
                     <th>Applied From</th>
                     
                </tr>
            </thead>
            <tbody>
                {users && users.map((user, index) => (
                    <tr key={index}>
                        <td>{user.name}</td>
                        <td>{user.email}</td>
                        <td>{user.phone}</td>
                        <td>{user.message}</td>
                        <td>{user.applyfrom}</td>
                        
                       
                      
                    </tr>
                ))}
            </tbody>
        </table>
      
    </div>
  )
}
