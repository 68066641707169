import React from 'react'

import { useState, useEffect } from 'react'
import apiUrl from '../../../CustomVar'
import http from './../../../http'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import {  useParams } from 'react-router-dom';

export default function EditMentor(props) {

    

    const [input, setInput] = useState({});
    const [colleges, setColleges] = useState([]);
    const [collegeId, setCollegeId] = useState('');
    const [courses, setCourses] = useState([]);
    

    const { id } = useParams();
    const [btnLoading, setBtnLoading] = useState(false);
    const [messages, setMessages] = useState();
    const [mentorpic, setMentorpic] = useState();
    const [idcard, setidcard] = useState();


    useEffect(() => {

        document.title = "Edit Mentor";
        fetchMentor();
        fetchColleges();
    }, [])

    const fetchMentor = () => {
        http.get('/mentor-edit/' + id)
            .then(response => {
                
                setInput(
                    {
                        id: response.data.id,
                        course: response.data.course_id,
                        college: response.data.collage_id,
                        name: response.data.name,
                        phone: response.data.phone,
                        email: response.data.email,
                        description: response.data.description,
                        is_top:response.data.is_top

                    }
                )
                console.log(response.data.pic)
                setMentorpic(response.data.pic);
                setidcard(response.data.idcard);
            })
            .catch(e => {
                console.log(e);
            });
    }
    const fetchColleges = async () => {
        await http.get('/colleges-dropdown')
            .then(response => {
                setColleges(response.data)
            })
            .catch(e => {
                console.log(e);
            });
    }
    useEffect(() => {
        const getCoursesByCollege = async () => {
            await http.get('/courses-by-college/'+collegeId)
                .then(response => {
                    setCourses(response.data)
                })
                .catch(e => {
                    console.log(e);
                });
        }
        getCoursesByCollege();

    }, [collegeId]);
    const handleCollege = (e) => {
        const getcollegeid = e.target.value;
        setCollegeId(getcollegeid);
        const { name, value } = e.target
        setInput(values => ({ ...values, [name]: value }))
    }
    const handleChange = (e) => {

        const { name, value } = e.target
        if (name === 'pic') {
            setInput(values => ({
                ...values,
                [name]: e.target.files[0],
            }))
        }else if (name === 'idcard') {
            setInput(values => ({
                ...values,
                [name]: e.target.files[0],
            }))
        } else setInput(values => ({ ...values, [name]: value }));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(input);
        setBtnLoading(true);
        http.post('/mentor-update', input)
            .then(response => {
                setMessages("Mentor updated successfully");
                setBtnLoading(false);
                toast.success("Mentor has been successfully created !")
                console.log(messages);
            })
            .catch(e => {
                console.log("Not added");
                setBtnLoading(false);
            });
    }


    return (
        <div>

            <br></br>
            <ToastContainer  />
            {messages &&

                <div className="alert alert-primary" role="alert">
                    {messages}
                </div>

            }

            <div style={{ border: "2px solid black", padding: "50px" }}>

                <h2>Edit Mentor</h2>

                <br></br>
                <div className="row mb-4">
                <div className="form-group col-md-4">
                        <label className="mb-2">Colleges</label>
                        <select name="college" className="form-control" onChange={ (e)=>handleCollege(e)}>
                            <option>--Select Collage--</option>
                            {
                                Object.entries(colleges).map(([key, value])=>{
                                    return (
                                        <option value={key}>{value}</option>
                                    )
                                })
        
                            }
                        </select>
                    </div>
                    <div className="form-group col-md-4">
                        <label className="mb-2">Course</label>
                        <select name="course" className="form-control" onChange={ (e)=>handleChange(e)}>
                            <option>--Select Course--</option>
                            {
                               courses.map((item) => (
                                    <option key={item.id} value={item.id}> {item.name}</option>
                                ))
        
                            }
                        </select>
                    </div>
                </div>
                <div className="row mb-4">
                <div className="form-group col-lg-4 mb-3">
                    <label htmlFor="name">Name</label>
                    <input type="text" className="form-control" id="name" name="name" value={input.name || ''} onChange={handleChange} placeholder="Enter name" />
                </div>
                <div className="form-group col-lg-4  mb-3">
                    <label htmlFor="name">Email</label>
                    <input type="email" className="form-control" id="email" name="email" value={input.email || ''} onChange={handleChange} placeholder="Enter email" />
                </div>
                <div className="form-group col-lg-4 mb-3">
                    <label htmlFor="name">Phone</label>
                    <input type="number" className="form-control" id="phone" name="phone" value={input.phone || ''} onChange={handleChange} placeholder="Enter phone" />
                </div>
                <div className="form-group col-lg-4 mb-3">
                    <label htmlFor="name">Photo</label>
                    <input
                        type="file"
                        className="form-control"
                        label="Image"
                        name="pic"
                        onChange={handleChange}

                    />
                    <img className='tableImg' src={`${apiUrl}/storage/mentor/${mentorpic}`} />
                </div>
                <div className="form-group col-lg-4 mb-3">
                    <label htmlFor="name">IdCard</label>
                    <input
                        type="file"
                        className="form-control"
                        label="Image"
                        name="idcard"
                        onChange={handleChange}

                    />
                    <img className='tableImg' src={`${apiUrl}/storage/idcard/${idcard}`} />
                </div>
                <div className="form-group col-lg-12 mb-3">
                    <label htmlFor="name">About Mentor</label>
                    <textarea rows={5} cols={52} className="form-control" id="description" name="description" value={input.description || ''} onChange={handleChange} placeholder="Enter description" />
                </div>
                <div className="form-group col-md-4">
                        <label className="mb-2">Is Top?</label>
                        <select name="is_top" className="form-control" onChange={ (e)=>handleChange(e)}>
                            <option>--Select Course--</option>
                            <option  value="1" selected={input.is_top==1} >Top</option>
                            <option  value="0" selected={input.is_top==0}>None Top</option>
                        </select>
                    </div>
                </div>
                <br></br>

                {
            btnLoading ? <>
            <button class="btn btn-primary" type="button" disabled>
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Loading...
          </button>
          </> : <>
          <button type="submit" onClick={handleSubmit} className="btn btn-primary form-submit">Update</button>
          </>
          }


            </div>

        </div>
    )
}
