import React from "react";
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import http from '../../http'
import "./Modal.css";
import Loc from './../../images/loc.png'

const City = ({ setOpenModal,sendDataToParent,sendParamToParent }) => {
  const [input, setInput] = useState({});
  const [cities, setCities] = useState([]);
  const [cityId, setCityId] = useState();
 const [btnLoading, setBtnLoading] = useState(false);
  const { courseId,type } = useParams();

  useEffect(() => {
    fetchCity();

  }, [])
  const fetchCity = async () => {
    try {
      const res = await http.get('/college-city');
      const response = res; // Response received
      setCities(response.data)
      
    } catch (error) {
     
      console.error(error);
    }
  };
const handleChange=(e)=>{
  setCityId(e.target.value)
}
const submitFinder = async (e) => {
  e.preventDefault();
  setBtnLoading(true);
  if(type==='mentor'){
    var urlName='/get-experts';
  }else if(type==='college'){
    var urlName='/get-colleges';
  }else{
    var urlName='/get-experts';
  }
  await http.post(urlName, { type: type,course:courseId,city:cityId })
      .then(response => {
        sendDataToParent(response.data);
        sendParamToParent({courseId:courseId,cityId:cityId});
        setBtnLoading(false);
        setOpenModal(false);
      })
      .catch(e => {
        setBtnLoading(false);
          console.log(e);
      });
}
  return (
    <>

      <div className="modalBackground col">
        <div className="modalContainer">
          
          <form>
            <div className="mt-2 mb-2 text-center">
            <div className="popup-icon">
              <img  src={Loc} alt="location" height="80" width="76" title="location" />
             </div>
             {type=='college'?<p className="popup-heading">Find best college across india</p>:<p className="popup-heading">Find an {type} near you!</p> }
             
             <p className="popup-subheading">Enter your City so we can show you {type} in your area</p>
              <div className="col-lg-12 mb-5">
              <select id="courses_search_select" onChange={(e)=>handleChange(e)} className="courses_search_select courses_search_input">
                      <option>Select your city</option>
                      {
                        cities?cities.map((city, index) => (
                                    <option key={index} value={city.id}>{city.name}</option>
                                ))
                                :''
                            }
                      
                    </select>
                    {
                btnLoading ? <>
                  <button class="btn btn-primary btn-lg" type="button" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                  </button>
                </> : <>
                  <button type="submit" className="btn btn-primary ms-3 btn-lg" onClick={submitFinder}>Submit</button>
                </>
              }

              
              </div>
            </div>

            
          </form>
        </div>
      </div>



    </>

  )
}

export default City;