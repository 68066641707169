import React from 'react';
import Footer from '../Footer';
import { FaSquareFacebook, FaTwitter, FaInstagram } from "react-icons/fa6";
import network from './../../../images/gift/network.jpg';
import growths from './../../../images/other/growths.png'
import payment from './../../../images/other/payment.png';
import offer from './../../../images/other/offer.jpg';
import training from './../../../images/other/training.png';
import graduate from './../../../images/gift/graduate.jpg';
import globally from './../../../images/other/globally.jpg';
import students from './../../../images/gift/students.jpg';
import officestud from './../../../images/gift/officestud.jpg';
import businesspartner from './../../../images/gift/businesspartner.jpg';
import techsupport from './../../../images/gift/tech-support.jpg';
import educirti from './../../../images/gift/educirti.png';
import events from './../../../images/gift/events.png';
import data from './../../../images/gift/data.png';
import sdcet from './../../../images/gift/sdcet.webp';
import abes from './../../../images/gift/abes.jpeg';
import ims from './../../../images/gift/ims.jpg';
import rkgit from './../../../images/gift/rkgit.jpg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import http from '../../../http'

import { useEffect,useState } from 'react'
const ChanelPartner = () => {
    const [click, setClick] = useState(false)
    const handleClick = () => setClick(!click)
 const closeMenu = () => setClick(false)
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []) 

   const [input, setInput] = useState({
      name: "",
      email:"",
      phone: "",
      message:"",
      applyfrom:'Chanel Partner',
    });
    const [errors, setErrors] = useState({
      name: "",
      email:"",
      phone: "",
      message:""
    });
    const [btnLoading, setBtnLoading] = useState(false);
  
  const handleChange=(e)=>{
    const { name, value } = e.target;
    setInput(values => ({ ...values, [name]: value }));
    validateField(name, value);
  }
  const validateField = (name, value) => {
    let error = "";
  
    switch (name) {
      case "name":
        error =
          value.trim().length >= 3
            ? ""
            : "Name must be at least 3 characters long.";
        break;
  
      case "email":
        error = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
          ? ""
          : "Enter a valid email address.";
        break;
  
      case "phone":
        error = /^\d{10}$/.test(value)
          ? ""
          : "Mobile number must be 10 digits.";
        break;
      case "message":
          error =
            value.trim().length >= 10
              ? ""
              : "Message must be at least 10 characters long.";
          break;
      default:
        break;
    }
  
    setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
  }
  const submitFinder = async (e) => {
    e.preventDefault();
    const isValid = Object.values(errors).every((err) => err === "") &&
        Object.values(input).every((value) => value.trim() !== "");
    if (isValid) {
      if (isValid) {
          setBtnLoading(true);
          await http.post('/store-otherapplicant', { ...input ,applyfrom:'Chanel Partner'})
            .then(response => {
              setBtnLoading(false);
              toast.success("Thank you for submitting ! We will revert back you soon")
              setInput('');
              
              })
            .catch(e => {
              setBtnLoading(false);
              console.log(e);
            });
        }
    }else{
      alert("Please fix the errors before submitting.");
    }
    
    
   
  }

    return (
        <>
            <section className="loan-bg pb-5 pt-5 position-relative loan-apply-banner secWrap">

                <div className="container">

                    <div className="row">
                        <div className="col-lg-6">
                            <h2 className="f_size_40 wt-8 text-dark l_height45  mb_50 loan-heading loan-apply-new">
                                Your Success is our success - We're Here to See  <br />
                                <span className="c_dark">You Grow</span>
                            </h2>
                            <p className="ft-17 text-dark">College Dwar is more than a plateform . Its your trusted partner to help you do what do best:
                                help as many students as possible fullfill their education dreams</p>
                                <a href='#ChanelPartnerForm'><button className="btn btn-cd btn-lg mt-4" onClick={closeMenu}>Partner with Us</button></a>
                        </div>
                        <div className="col-lg-6  ml-auto my-auto">
                            <img className="w-100" src={businesspartner} />
                        </div>
                    </div>
                </div>
            </section>


            <section className='secWrap bg-cd-light'>
                <div className="container">
                    <div className='row'>

                        <div className="col-lg-4 mb-4">
                            <div className='d-flex align-self-center'>
                                <div className='mr-2'>
                                    <img src={growths} />
                                </div>
                                <div className='pl-10'>
                                    <p className='m-0 ft-12'>GROWTH</p>
                                    <h6> Grow Quicker with Lower Costs</h6>
                                    <p>College Dwar Chanel Partners have grown more than 5X in the last 5 years.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-4">
                            <div className='d-flex align-self-center'>
                                <div className='mr-2'>
                                    <img src={payment} />
                                </div>
                                <div className='pl-10'>
                                    <p className='m-0 ft-12'>ASSURED PAYOUT</p>
                                    <h6> Trusted and Transparent Payments</h6>
                                    <p>We pay you at multiple stages of the application and enrollment process.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-4">
                            <div className='d-flex align-self-center'>
                                <div className='mr-2'>
                                    <img src={globally} />
                                </div>
                                <div className='pl-10'>
                                    <p className='m-0 ft-12'>ACCESS</p>
                                    <h6> More Collegs Globally</h6>
                                    <p>More than 1000+ colleges across delhi Ncr , Pune , Banglore, Mumbai , Kolkata and dehradun.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-4">
                            <div className='d-flex align-self-center'>
                                <div className='mr-2'>
                                    <img src={offer} />
                                </div>
                                <div className='pl-10'>
                                    <p className='m-0 ft-12'>OFFER MORE</p>
                                    <h6> One Platform for Every Need</h6>
                                    <p>With College Dwar  discover exclusive opportunities to offer value and earn more.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-4">
                            <div className='d-flex align-self-center'>
                                <div className='mr-2'>
                                    <img src={training} />
                                </div>
                                <div className='pl-10'>
                                    <p className='m-0 ft-12'>INSIGHTS</p>
                                    <h6> Industry-leading Insights and Training</h6>
                                    <p>Receive best-in-class training on Education Industry and AI-driven Insights to future-proof their business.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            <section className='secWrap bg-cd'>
                <div className="container-xxl py-5">
                    <div className="container">
                        <div className="row g-4">
                            <div className='col-sm-12 text-center text-white'><h3 className="mb-4 wt-6">Access More Colleges Perks & Faster Commissions</h3>
                                <p>Here to help you grow your business by offering the best opportunity and supports</p>
                            </div>
                            <div className="col-lg-4 col-sm-12 wow fadeInUp">
                                <div className="service-item text-center pt-3">
                                    <div className="impact">
                                        <div className='impactImg'><img src={graduate} /></div>
                                        <div><h6 className="mb-3">Best Commissions</h6></div>
                                        <p>Quick and reliable commissions so you get paid for your hard work.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-12 wow fadeInUp">
                                <div className="service-item text-center pt-3">
                                    <div className="impact">
                                        <div className='impactImg'><img src={students} /></div>
                                        <div><h6 className="mb-3">Perks & Rewards</h6></div>
                                        <p>Earn bonuses and have access to top training to support your growth.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-12 wow fadeInUp">
                                <div className="service-item text-center pt-3">
                                    <div className="impact">
                                        <div className='impactImg'><img src={officestud} /></div>
                                        <div><h6 className="mb-3 ft-30">Access to 1000+ Colleges </h6></div>
                                        <p>Top programs at the top colleges in the most desirable destinations at your fingertips.</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <section className='secWrap bg-cd-light'>
                <div className="container-xxl py-5">
                    <div className="container">
                        <div className="row g-4">
                            <div className='col-sm-12 mb-4 text-center'>
                                <h3 className="mb-0">We are Invested in Caring for You and the
                                    Ecosystem of International Education</h3>
                            </div>
                            <div className="col-lg-6 col-sm-6 wow fadeInUp">
                                <div className="service-item text-left suppDiv">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="p-2"> <img className="suppImg" src={techsupport} /></div>
                                        </div>
                                        <div className="col-sm-6">
                                            <h5 className="mb-3 mt-5">Local Support</h5>
                                            <p>Our international recruitment experts are there to support you every step of the way.</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-6 wow fadeInUp">
                                <div className="service-item text-left suppDiv">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="p-2"> <img className="suppImg" src={educirti} /></div>
                                        </div>
                                        <div className="col-sm-6">
                                            <h5 className="mb-3 mt-5">Education</h5>
                                            <p>Expand your knowledge and stay ahead of your competitors with guided online courses and certificates.</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-6 wow fadeInUp">
                                <div className="service-item text-left suppDiv">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="p-2"> <img className="suppImg" src={events} /></div>
                                        </div>
                                        <div className="col-sm-6">
                                            <h5 className="mb-3 mt-5">Events and Webinars</h5>
                                            <p>Regular online webinars, training, and events to keep you updated on the latest trends and regulations.</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-6 wow fadeInUp">
                                <div className="service-item text-left suppDiv">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="p-2"> <img className="suppImg" src={data} /></div>
                                        </div>
                                        <div className="col-sm-6">
                                            <h5 className="mb-3 mt-5">Data and Insights</h5>
                                            <p>Industry leading insights and knowledge that helps you plan, expand, and achieve your goals.</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        
            <section className='secWrap bg-cd-light'>
                <div className="container-xxl py-5">
                    <div className="container">
                        <div className="row g-4">
                            <div className='col-sm-12 mb-4 text-center'>
                                <h3 className="mb-0">We Connect You to the
                                            Right Colleges for Your Students</h3>
                            </div>
                            <div className="col-lg-3 col-sm-6 col-12 wow fadeInUp">
                                <div className="text-left suppDiv text-center">
                                  <div className="p-2"> <img className="suppImg" src={rkgit} /></div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6 col-12 wow fadeInUp">
                                <div className="text-left suppDiv text-center">
                                  <div className="p-2"> <img className="suppImg" src={abes} /></div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6 col-12 wow fadeInUp">
                                <div className="text-left suppDiv text-center">
                                  <div className="p-2"> <img className="suppImg" src={sdcet} /></div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6 col-12 wow fadeInUp">
                                <div className="text-left suppDiv text-center">
                                  <div className="p-2"> <img className="suppImg" src={ims} /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="loan-bg pb-5 pt-5  position-relative loan-apply-banner secWrap bg-cd" id='ChanelPartnerForm'>
<div className="container mb-5">
  <div className="row">
  
      
    <div className="col-lg-6 offset-md-3 text-white">
   <div className='ft-30 wt-6'> There's a more effective way to fill your  funnel and its start with College Dwar</div>
    <h6 className='ft-20 mt-4'>Fill a form</h6>
     
     <ToastContainer  />
        <form className="contact_form_box mt-5" >
                      <div className="row">
    
                        <div className="col-lg-12">
                          <div className="form-group text_box">
                            <input type="text" name="name" value={input.name || ''} onChange={handleChange} placeholder=" Name" />
                            {errors.name && <span style={{ color: "red" }}>{errors.name}</span>}
                          </div>
                        </div>
    
                     
    
                        <div className="col-lg-12">
                          <div className="form-group text_box">
                            <input type="tel" name="phone" value={input.phone || ''} onChange={handleChange} placeholder="Phone No" />
                            {errors.phone && <span style={{ color: "red" }}>{errors.phone}</span>}
                           
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group text_box">
                            <input type="email" name="email" value={input.email || ''} onChange={handleChange} placeholder="Email" />
                            {errors.email && <span style={{ color: "red" }}>{errors.email}</span>}
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group text_box">
                            <input type="text" name="message" value={input.message || ''} onChange={handleChange} placeholder="Job Title" />
                            {errors.message && <span style={{ color: "red" }}>{errors.message}</span>}
                          </div>
                        </div>                    
                        <div className="col-lg-12 text-center mt-4">
                        {
                    btnLoading ? <>
                      <button class="btn btn-primary" type="button" disabled>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Loading...
                      </button>
                    </> : <>
                      <button type="submit" className="btn btn-primary btn-larg" onClick={submitFinder}>Submit</button>
                     
                    </>
                  }
                         
                      </div>
                      </div>
    
                    </form>
    </div>
  </div>
</div>
</section>
            <Footer />
        </>
    );
}

export default ChanelPartner;