import React from 'react'
import { useState,useEffect } from 'react'
import GetInTouchPop from '../../modal/GetInTouch';
import { Link,useParams } from 'react-router-dom';
import { FaSquareFacebook,FaTwitter,FaInstagram } from "react-icons/fa6";
export default function ExpertItem({item,requestedCoures}) {
	const [getInTouchmodal, setGetInTouchmodal] = useState(false);
	const [requestedCourseId, setRequestedCourseId] = useState(false);
	const { courseId } = useParams();
	const { REACT_APP_APIURL } = process.env;
	useEffect(() => {
		userRequestedCourse();
	}, [])
	const GetInTouch=()=>{
		setGetInTouchmodal(true)
	}
    const userRequestedCourse=()=>{
		if(requestedCoures===undefined){
			setRequestedCourseId(courseId)
		}else{
			setRequestedCourseId(requestedCoures)
		}
	}
	
	
  return (
    <>
	   
		{getInTouchmodal && <GetInTouchPop setGetInTouchmodal={setGetInTouchmodal} item={item}  requestedCoures={requestedCoures}   />}
		<div className="col-lg-3 col-md-6 team_col">
		
			<div className="team_item1">
				<div className="team_image1">
				<img className="expertsImg1" src={`${REACT_APP_APIURL}/storage/${item.data_type}/${item.pic}`} alt='team1' />
				</div>
				<div className="team_body1">
				<div className="team_title1">{item.name}</div>
				<div className="subtitle1 ft-12 mb-2">{item.email}</div>
				{item.data_type=='college'?
				<table className='table table-bordered ft-12 wt-6'>
					<tr>
						<td className='pt-1 pb-1 border-top-0'>Average CTC</td>
						<td className='pt-1 pb-1 border-top-0'>{item.average_ctc}</td>
					</tr>
					<tr>
						<td className='pt-1 pb-1'>Highest CTC</td>
						<td className='pt-1 pb-1'>{item.highest_ctc}</td>
					</tr>
					<tr>
						<td className='pt-1 pb-1'>Fees</td>
						<td className='pt-1 pb-1'>{item.fees}</td>
					</tr>
				</table>
				:''}	
					
					
					<div className='text-center mt-4 mb-4'><button className='btn btn-secondary' onClick={GetInTouch}>Apply Now</button></div>
				</div>
			</div>
			
		</div>
	</>
  )
}
