import React from 'react';
import Footer from '../Footer';

import collegeFair from './../../../images/collegefair.jpg';
import collegeFairImg from './../../../images/other/collegeFair.jpg';
import lifsave100 from './../../../images/gift/lifsave100.png';
import qrcode100 from './../../../images/gift/qrcode100.png';
import map from './../../../images/gift/map.png';
import HowItWork from '../sections/HowItWork';
import { useState,useEffect } from 'react'
import ApplyForm from '../../modal/ApplyForm';

const CollegeFair = () => {
    const [applyFormModal, setApplyFormModal] = useState(false);
    const applyNowFun=()=>{
        setApplyFormModal(true)
    }
    useEffect(() => {
    window.scrollTo(0, 0)
    }, [])
  return(
  <>
  {applyFormModal && <ApplyForm setApplyFormModal={setApplyFormModal} applyfrom={'College Fair Know More'}  />}
  <section className="loan-bg pb-5 pt-5 position-relative loan-apply-banner secWrap">

<div className="container">

  <div className="row">
    <div className="col-lg-6">
      <h2 className="f_size_40 wt-8 text-dark l_height45  mb_50 loan-heading loan-apply-new">
      Power up your 2025 application  seasions with    <br />
        <span className="c_dark">College Dwar</span>
        <br />
        Education.
      </h2>
      <p>
      College Dwar's exclusive counselor connect networking events allow university representatives to interact with
       high school counselors in small, 
      intimate settings, to share about their latest programs and explore the best opportunities for students.
      </p>
     </div>
    <div className="col-lg-6  ml-auto my-auto">
      <img className="w-100" src={collegeFairImg} />
    </div>
  </div>
</div>
</section>
   <section className='secWrap bg-cd-light'>
<div  className="container-xxl py-5">
        <div  className="container">
            <div  className="row g-4">
            <div className='col-sm-12 mb-4 text-center'>
               <h3 className="mb-0">Why Participate</h3>
            </div>
                <div  className="col-lg-4 col-sm-12 wow fadeInUp">
                    <div  className="service-item text-left">
                        <div  className="p-4">
                            <ul className='nbpList'>
                                <li>Exclusive access to students, counselors, and parents from top local institutions in College Dwar’s high school network</li>
                            </ul>
                           
                        </div>
                    </div>
                </div>
                <div  className="col-lg-4 col-sm-12 wow fadeInUp">
                    <div  className="service-item text-left">
                        <div  className="p-4">
                       
                        <ul className='nbpList'>
                                <li>Establish meaningful, in-person engagements with prospective students, parents, and school counselors.</li>
                            </ul>
                            
                        </div>
                    </div>
                </div>
                <div  className="col-lg-4 col-sm-12 wow fadeInUp">
                    <div  className="service-item text-left">
                        <div  className="p-4">
                       
                        <ul className='nbpList'>
                                <li>Travel with a complete peace of mind with our holistic and full-service tour itineraries.</li>
                            </ul>
                            
                        </div>
                    </div>
                </div>
                <div  className="col-lg-4 col-sm-12 wow fadeInUp">
                    <div  className="service-item text-left">
                        <div  className="p-4">
                       
                        <ul className='nbpList'>
                                <li>Ease of access to customized post-tour data with College Dwar’s lead tracking tool inbuilt in Explore.</li>
                            </ul>
                           
                    </div>
                </div>
                </div>
                <div  className="col-lg-4 col-sm-12 wow fadeInUp">
                    <div  className="service-item text-left">
                        <div  className="p-4">
                       
                        <ul className='nbpList'>
                                <li>Learn from industry stalwarts and world-renowned academicians</li>
                            </ul>
                            
                        </div>
                    </div>
                </div>
                
               
            </div>
        </div>
    </div>
</section>
   <section className='secWrap'>
<div  className="container-xxl py-5">
        <div  className="container">
            <div  className="row g-4">
            <div className='col-sm-12 text-center'><h3 className="mb-4">Track you return ROI with clear Actionable Insight</h3></div>
                <div  className="col-lg-4 col-sm-6 wow fadeInUp">
                    <div  className="service-item text-center pt-3">
                        <div  className="p-4">
                        <div className='text-center mb-2'><img src={qrcode100} /></div>
                           
                            <p>Students will be provided with a QR code for scanning at your university booths as part of our High School Tours</p>
                            <br /> <br />
                        </div>
                    </div>
                </div>
                <div  className="col-lg-4 col-sm-6 wow fadeInUp" >
                    <div  className="service-item text-center pt-3">
                        <div  className="p-4">
                        <div className='text-center mb-2'><img src={map} /></div>
                           
                            <p>Upon scanning, student leads will be captured and mapped seamlessly to College Dwar’s Explore dashboard</p>
                            <br /> <br /> <br />
                        </div>
                    </div>
                </div>
                <div  className="col-lg-4 col-sm-6 wow fadeInUp" >
                    <div  className="service-item text-center pt-3">
                        <div  className="p-4">
                        <div className='text-center mb-2'><img src={lifsave100} /></div>
                            
                            <p>Students apply through our CommonApp integration, UCAS Center and Direct Apply,
                             which allows you to monitor the progress of the students, from Connections to applications and enrolments</p>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</section>
<section  className="pricing-section spad secWrap">
        <div  className="container">
            <div  className="row">
                <div  className="col-lg-12 col-md-12 mb-4">
                    <div  className="section-title normal-title text-center">
                        <h3>Tour Shedule</h3>
                    </div>
                </div>
              
            </div>
            <div  className="row monthly__plans active">
                <div  className="col-lg-4 col-md-6 col-sm-6">
                    <div  className="pricing__item">
                        <h3>North <span>India</span></h3>
                        <ul>
                            <li>Dec - Jan </li>
                            <li>Jaipur, Agra, Kanpur, Varanasi, Delhi, Lucknow etc</li>
                            <li>15 - 18 high school visits, 3 Counselor Connects</li>
                            
                        </ul>
                       
                    </div>
                </div>
                <div  className="col-lg-4 col-md-6 col-sm-6">
                    <div  className="pricing__item">
                    <h3>East <span>India</span></h3>
                        <ul>
                            <li>Jan - Feb</li>
                            <li>Kolkata, Patna, Ranchi , Jamshedpur, Raipur, Assam etc.</li>
                            <li>15 - 18 high school visits, 3 Counselor Connects</li>
                        </ul>
                       
                    </div>
                </div>
                <div  className="col-lg-4 col-md-6 col-sm-6">
                    <div  className="pricing__item">
                    <h3>West <span>India</span></h3>
                        <ul>
                             <li>Feb - March </li>
                            <li>Ahmedabad, Varodara, Mumbai, Udaipur , Jodhpur etc.</li>
                            <li>15 - 18 high school visits, 3 Counselor Connects</li>
                        </ul>
                       
                    </div>
                </div>
                
            </div>
            
        </div>
    </section>
    <section className='secWrap bg-cd-light'>
                <div className="container">
                    <div className='row'>
                       
                        <div className="col-lg-12 text-center p-5">
                       
                        <h2>Interested or want to know more</h2>
                           
                        </div>
                        <div className="col-lg-12 mt-2 text-center">
                           <button className="btn btn-cd btn-lg" onClick={applyNowFun}>Apply Now</button> 
                           <button className="btn btn-warning-outlinne btn-lg">Download Tour Package to Share</button> 
                        </div>
                    </div>
                </div>

            </section>

  <Footer />
  </>
        );
    }

    export default CollegeFair;