import React from 'react';
import { useEffect,useState } from 'react'
import Footer from '../Footer';
import WhyChoose from '../sections/WhyChoose';
import Brand from '../sections/Brand';
import HowItWork from '../sections/HowItWork';
import Testimonial from '../testimonial/Testimonial';
import FooterApply from '../sections/FooterApply';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import http from '../../../http'

const Loan = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

const [input, setInput] = useState({
      name: "",
      email:"",
      phone: "",
    
      applyfrom:'Loan',
    });
    const [errors, setErrors] = useState({
      name: "",
      email:"",
      phone: "",
     
    });
    const [btnLoading, setBtnLoading] = useState(false);
  
  const handleChange=(e)=>{
    const { name, value } = e.target;
    setInput(values => ({ ...values, [name]: value }));
    validateField(name, value);
  }
  const validateField = (name, value) => {
    let error = "";
  
    switch (name) {
      case "name":
        error =
          value.trim().length >= 3
            ? ""
            : "Name must be at least 3 characters long.";
        break;
  
      case "email":
        error = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
          ? ""
          : "Enter a valid email address.";
        break;
  
      case "phone":
        error = /^\d{10}$/.test(value)
          ? ""
          : "Mobile number must be 10 digits.";
        break;
      
      default:
        break;
    }
  
    setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
  }
  const submitFinder = async (e) => {
    e.preventDefault();
    console.log(errors)
    const isValid = Object.values(errors).every((err) => err === "") &&
        Object.values(input).every((value) => value.trim() !== "");
       
    if (isValid) {
      if (isValid) {
          setBtnLoading(true);
          await http.post('/store-otherapplicant', { ...input ,applyfrom:'Loan'})
            .then(response => {
              setBtnLoading(false);
              toast.success("Thank you for submitting ! We will revert back you soon")
              setInput('');
              
              })
            .catch(e => {
              setBtnLoading(false);
              console.log(e);
            });
        }
    }else{
      alert("Please fix the errors before submitting.");
    }
    
    
   
  }

  return (
    <>
      <section id="TopForm" className="loan-bg pb-5 pt-5 position-relative loan-apply-banner secWrap bg-cd-light">

        <div className="container">

          <div className="row">


            <div className="col-lg-6 midsec">
              <h2 className="capt-text wt-8 text-dark l_height45  mb_50 loan-heading loan-apply-new">

              We Turn your <span className="c_dark">dreams</span> <br />
               Into Reality!
              </h2>
              <p className="ft-25 text-dark wt-6">Get funded with College Dwar Education Loans</p>
              <ul className="list-unstyled mb-30 pl-0 pr_20 loan-list">
                <li>10+ Banking Partners</li>
                <li>Lower interest rates</li>
                <li>Hassle free process</li>
                <li>Quick Sanctions within 48 Hrs</li>
              </ul>
            </div>

            <div className="col-lg-6  ml-auto my-auto">

              <div className="apply-now-form shadow bg-light pl-4 pr-4 py-5" id="myForm">
                <div className='text-center'><h2><span>Lets Your Deams Take Flight</span></h2></div>
                <hr />


   <ToastContainer  />
                <form className="contact_form_box" id="applynowform" >
                  <div className="row">

                    <div className="col-lg-6">
                      <div className="form-group text_box">
                        <input type="text" name="name" value={input.name || ''} onChange={handleChange} placeholder="First Name" />
                        {errors.name && <span style={{ color: "red" }}>{errors.name}</span>}
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group text_box">
                        <input type="text" id="lastname" name="lname" placeholder="Last Name" value="" />
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group text_box">
                        <input type="tel" maxlength="10" id="number" name="phone" value={input.phone || ''} onChange={handleChange} placeholder="Contact Number"  />
                      </div>
                      {errors.phone && <span style={{ color: "red" }}>{errors.phone}</span>}
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group text_box">
                        <input type="email" name="email" value={input.email || ''} onChange={handleChange} id="email" placeholder="E-mail ID"  />
                      </div>
                      {errors.email && <span style={{ color: "red" }}>{errors.email}</span>}
                    </div>


                    <div className="col-lg-6">
                      <div className="form-group text_box">

                        <select className="form-control" id="placeOfStudy" name="placeOfStudy" placeholder="Select Course">
                          <option value="">Select Course</option>
                          <option value="Tech">B.Tech</option>
                          <option value="MBA">MBA</option>
                          <option value="MCA">MCA</option>
                        </select>
                      </div>
                    </div>
                    

                    <div className="col-lg-12">
                      <input type="checkbox" id="checkbox" name="permission" value="Agreed To use data for communication" required="" checked="" />
                      <span className="text-dark"> I authorize College Dwar teams to connect with me over whatsapp and calls. </span>

                    </div>
                    <div className="col-lg-12 text-center">
                    {
                    btnLoading ? <>
                      <button class="btn btn-primary" type="button" disabled>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Loading...
                      </button>
                    </> : <>
                      <button type="submit" className="btn_core f_size_22" onClick={submitFinder}>Submit</button>
                     
                    </>
                  }
                     
                  </div>
                  </div>

                </form>


              </div>

            </div>


          </div>


        </div>
      </section>
     
      <Brand />
      <WhyChoose />
      <HowItWork />
      <Testimonial />
      <FooterApply />
      <Footer />
    </>
  );
}

export default Loan;