import React from 'react'
import { useState } from 'react'

import sunil from "./../../../images/topMentors/sunily.jpg";
import neetu from "./../../../images/topMentors/neetu.jpg";
import tanu from "./../../../images/topMentors/tanu.jpg";
import minal from "./../../../images/topMentors/minal.jpg";

import loc from "./../../../images/other/loc.png";
import { FaMap } from "react-icons/fa6";
import { FaStar } from "react-icons/fa6";
import ApplyForm from '../../modal/ApplyForm';
import GetInTouchPop from '../../modal/GetInTouch';
const { REACT_APP_APIURL } = process.env;


export default function Topmentors({item}) {
    const [getInTouchmodal, setGetInTouchmodal] = useState(false);
    const [requestedCoures, setrequestedCoures] = useState(null);
   
   
    
  
    const GetInTouch=(e)=>{
        
		setGetInTouchmodal(true)
       
	}

  return (
    <>
    
    {getInTouchmodal && <GetInTouchPop setGetInTouchmodal={setGetInTouchmodal} item={item}  requestedCoures={requestedCoures}   />}
   
           
                <div className="col-lg-3 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="0.5s">
                <div className="course-item bg-light">
                        <div className="position-relative overflow-hidden">
                        <div className="mentor-rate-wrap"><FaStar className='ment-rating' /> <span className='ment-rp'>4.3</span></div> 
                            <img className='img-fluid course-item-img' src={`${REACT_APP_APIURL}/storage/mentor/${item.pic}`} alt='team1' />
                        </div>
                        <div className="pb-2 bg-white">
                        <div className="text-center p-2 pb-0">
                            <div className="ment-name">{item.name}</div>
                            <div className="mb-1 ment-degi">
                              <small> <img src={loc} className='loc' /> {item.loc} </small>
                            </div>
                            <div className='ment-exp mb-2 wt-6'>{item.college}</div>
                        </div>
                        <div class="text-center border-top pt-2">
                        <button  className=" btn btn-md btn-primary" onClick={()=>{GetInTouch(item.id)}}>Consult Now</button>
                        </div>
                        </div>
                        
                    </div>
                </div>
               
           
    </>
  )
}
