import React from 'react';
import Footer from '../Footer';
import refImg from './../../../images/refer1.jpg'
import ipad from './../../../images/gift/ipad-full-size.jpg';
import iphone14 from './../../../images/gift/iphone14.jpg';
import mackbookpro from './../../../images/gift/mackbookpro.jpg';
import smartwatch from './../../../images/gift/smartwatch.jpg';
import Referal from '../reviews/Referal';
import WantMore from '../sections/WantMore';
import { useState,useEffect } from 'react'
import ApplyForm from '../../modal/ApplyForm';
const Refer = () => {
  const [applyFormModal, setApplyFormModal] = useState(false);
  const applyNowFun=()=>{
		setApplyFormModal(true)
	}
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
{applyFormModal && <ApplyForm setApplyFormModal={setApplyFormModal} applyfrom={'Refer & Earn'}  />}
  
      <section className="loan-bg pb-5 pt-5 position-relative loan-apply-banner secWrap">

        <div className="container">

          <div className="row">
            <div className="col-lg-6">
              <h2 className="wt-8 text-dark l_height45  mb_50 loan-heading loan-apply-new">
              An Opportunity to launch someone's career earn up to  <br />
                <span className="c_dark">Rs 30K per referal</span>
              </h2>
              <p className="ft-17 text-dark wt-6">Your freind enjoy the highest pre applied fee waiver up to 50K . If they enroll using your referal code</p>
              <a className="btn btn-lg py-3 px-5 mt-2 btn-cd" onClick={applyNowFun}>Refer Now</a>
            </div>
            <div className="col-lg-6  ml-auto my-auto">
              <img className="w-100" src={refImg} />
            </div>
          </div>
        </div>
      </section>
      <section className='secWrap bg-cd-light'>
      <div  className="container">
        <div  className="row mt-30 mb-30">
        <div className='col-sm-12 text-center mb-4'>
      
        <h3 className="mb-0 sec-title-txt text-center">How to Earn?</h3>
               <p class="text-center mt-3 wt-6">If any of your friends family wants to take admission in any course / college across India you can refer and earn cash rewards</p>
        </div>
          <div  className="col-sm-12 col-md-4">
          
            <div  className="share-boxes">
              <img src="https://i.ibb.co/PtYrLNy/img1.png" alt="img1" border="0" />
              <p>Sign Up and add detail</p>
              <div className="mt-2">Let us know whose career you would like to enhance by signing up on our website</div>
            </div>
          </div>
          
          <div  className="col-sm-12 col-md-4">
            <div  className="share-boxes">
              <img src="https://i.ibb.co/P5TdfkT/img2.png" alt="img2" border="0" />
              <p>Your referel enrols for our program</p>
              <div className="mt-2">Our team will get in touch with your freind to complete the joining formalities.</div>
              {/* <img src="https://i.ibb.co/Sr5F70S/dotted-arrow1.png" alt="dotted-arrow1"  className="dotted-line" />
              <img src="https://i.ibb.co/Fqs2KxB/dotted-arrow2.png" alt="dotted-arrow2"  className="dotted-line2" /> */}
            </div>
          </div>
          
          <div  className="col-sm-12 col-md-4">
            <div  className="share-boxes">
              <img src="https://i.ibb.co/StC3RWk/img3.png" alt="img3" border="0" />
              <p>Earn referel benifits</p>
              <div className="mt-2">Once your freind makes the full payment you will get reward in 15 days by College Dwar</div>
            </div>
          </div>
          <div className='col-sm-12 mt-5 text-center'><button className='btn btn-plan btn-lg'>Refer Now</button></div>
        </div>
        </div>
       
      </section>
      <section className='secWrap'>
<div  className="container-xxl py-5">
        <div  className="container">
            <div  className="row g-4">
            <div className='col-sm-12 mb-4 text-center'>
            <h3 className="mb-0 sec-title-txt text-center">Milestone Rewards</h3>
               <p class="text-center mt-3 wt-6">Hit referal Target and get extra rewards along with cash bonus.</p>
            
            </div>
                <div  className="col-lg-6 col-sm-6 wow fadeInUp">
                    <div  className="service-item text-left">
                        <div  className="p-4">
                        <img className="giftImg" src={smartwatch} />
                            <h5  className="mb-3 mt-2">1-3 Admission</h5>
                            <p>You can get Smart Watch after 1-3 Admissions</p>
                        </div>
                    </div>
                </div>
                <div  className="col-lg-6 col-sm-6 wow fadeInUp" >
                    <div  className="service-item text-left">
                        <div  className="p-4">
                        <img className="giftImg" src={ipad} />
                            <h5  className="mb-3 mt-2">4-6 admission</h5>
                            <p>You can get I-Pad  after 4-6 Admissions</p>
                        </div>
                    </div>
                </div>
                <div  className="col-lg-6 col-sm-6 wow fadeInUp" >
                    <div  className="service-item text-left">
                        <div  className="p-4">
                        <img className="giftImg" src={iphone14} />
                            <h5  className="mb-3 mt-2">7-10 admission</h5>
                            <p>You can get I-Phone-14  after 7-10 Admissions</p>
                        </div>
                    </div>
                </div>
                <div  className="col-lg-6 col-sm-6 wow fadeInUp">
                    <div  className="service-item text-left">
                        <div  className="p-4">
                        <img className="giftImg" src={mackbookpro} />
                            <h5  className="mb-3 mt-2">10 + admission</h5>
                            <p><p>You can get Mc-Book Pro  after 10+ Admissions</p></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<Referal />
<WantMore />
      <Footer />
    </>
  );
}

export default Refer;