import React from 'react';
import {useEffect,useState } from 'react'
import Footer from '../Footer';
import contactus from "./../../../images/contactus.jpg";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import http from '../../../http'

const Contact = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

const [input, setInput] = useState({
      name: "",
      email:"",
      phone: "",
      message:"",
      applyfrom:'Contact Us',
    });
    const [errors, setErrors] = useState({
      name: "",
      email:"",
      phone: "",
      message:""
    });
    const [btnLoading, setBtnLoading] = useState(false);
  
  const handleChange=(e)=>{
    const { name, value } = e.target;
    setInput(values => ({ ...values, [name]: value }));
    validateField(name, value);
  }
  const validateField = (name, value) => {
    let error = "";
  
    switch (name) {
      case "name":
        error =
          value.trim().length >= 3
            ? ""
            : "Name must be at least 3 characters long.";
        break;
  
      case "email":
        error = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
          ? ""
          : "Enter a valid email address.";
        break;
  
      case "phone":
        error = /^\d{10}$/.test(value)
          ? ""
          : "Mobile number must be 10 digits.";
        break;
      case "message":
          error =
            value.trim().length >= 10
              ? ""
              : "Message must be at least 10 characters long.";
          break;
      default:
        break;
    }
  
    setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
  }
  const submitFinder = async (e) => {
    e.preventDefault();
    const isValid = Object.values(errors).every((err) => err === "") &&
        Object.values(input).every((value) => value.trim() !== "");
    if (isValid) {
      if (isValid) {
          setBtnLoading(true);
          await http.post('/store-otherapplicant', { ...input ,applyfrom:'Contact Us'})
            .then(response => {
              setBtnLoading(false);
              toast.success("Thank you for submitting ! We will revert back you soon")
              setInput('');
              
              })
            .catch(e => {
              setBtnLoading(false);
              console.log(e);
            });
        }
    }else{
      alert("Please fix the errors before submitting.");
    }
    
    
   
  }

    return (
        <>

            <section className="about-section secWrap">
                <div id="contact" className="form-3">
                    <div className="container">
                     
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="text-container">
                                    <img src={contactus} className='img-fluid' />
                                </div>
                            </div>
                            <div className="col-lg-6">

                            <div className='col-sm-12 text-start text-dark pb-2 mb-2'><h3 className="mb-4 wt-8">Enquiry Now</h3>
                                <p>Fill the form for any enquiry!</p>
                            </div>
                             <ToastContainer  />
                                <form id="contactForm">
                                    <div className="form-group mb-4">
                                        <label className="label-control" for="cname">Name</label>
                                        <input type="text" className="form-control" name="name" value={input.name || ''} onChange={handleChange} />
                                        {errors.name && <span style={{ color: "red" }}>{errors.name}</span>}
                                       
                                    </div>
                                    <div className="form-group mb-4">
                                        <label className="label-control" for="cemail">Email</label>
                                        <input type="email" className="form-control" name="email" value={input.email || ''} onChange={handleChange} />
                                        {errors.email && <span style={{ color: "red" }}>{errors.email}</span>}

                                        
                                    </div>
                                    <div className="form-group mb-4">
                                        <label className="label-control" for="cname">Phone</label>
                                        <input type="number" className="form-control" name="phone" value={input.phone || ''} onChange={handleChange} />
                                        {errors.phone && <span style={{ color: "red" }}>{errors.phone}</span>}
                                       
                                    </div>
                                    <div className="form-group mb-4">
                                        <label className="label-control" for="cmessage">Your message</label>
                                        <textarea rows={5} cols={52} className="form-control" name="message" value={input.message || ''} onChange={handleChange}></textarea>
                                        {errors.message && <span style={{ color: "red" }}>{errors.message}</span>}
                                       
                                    </div>
                                    <div className="form-group checkbox mb-4">
                                        <input type="checkbox" id="cterms" value="Agreed-to-Terms" required="" />I have read and agree to CD's stated <a href="privacy-policy.html">Privacy Policy</a> and <a href="terms-conditions.html">Terms Conditions</a>
                                        <div className="help-block with-errors"></div>
                                    </div>
                                    <div className="form-group text-center">
                                    {
                                        btnLoading ? <>
                                        <button class="btn btn-primary" type="button" disabled>
                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Loading...
                                        </button>
                                        </> : <>
                                        <button type="submit" className="btn btn-warning btn-lg" onClick={submitFinder}>Submit</button>
                                        
                                        </>
                                    }
                                       
                                    </div>
                                    <div className="form-message">
                                        <div id="cmsgSubmit" className="h3 text-center hidden"></div>
                                    </div>
                                </form>


                            </div>
                        </div>
                    </div>
                </div>

            </section>
            <Footer />
        </>
    );
}

export default Contact;