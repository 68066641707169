import React from 'react'
import { useState,useEffect } from 'react'
import GetInTouchPop from '../../modal/GetInTouch';
import CirtificateModal from '../../modal/CirtificateModal';
import http from "../../../http";
import { useParams } from 'react-router-dom';
import BreadCrumb from '../BreadCrumb';
export default function ExpertDetail() {
	const [getInTouchmodal, setGetInTouchmodal] = useState(false);
	const [getCirtificatemodal, setCirtificatemodal] = useState(false);
	const [pageLoading, setPageLoading] = useState(false);
	const [info, setInfo] = useState(false);
	const { REACT_APP_APIURL } = process.env;
	const {type,typeId,courseId} = useParams();
	const GetInTouch=()=>{
		setGetInTouchmodal(true)
	}
    const closeCirtificateModal=()=>{
        setCirtificatemodal(false)
    }
    const vieCertificate=()=>{
		setCirtificatemodal(true)
	}
	useEffect(() => {
		fetchInfo();
	}, [])
	const fetchInfo = () => {
        setPageLoading(true); 
        http.post('/expert-detail',{type:type,typeId:typeId})
            .then(response => {
                setInfo(response.data)
                setPageLoading(false);
            })
            .catch(e => {
                console.log(e);
                setPageLoading(false);
            });
    }
  return (
    <>
        <BreadCrumb pageName={info.name} />
        {getInTouchmodal && <GetInTouchPop setGetInTouchmodal={setGetInTouchmodal} item={info}  requestedCoures={courseId}     />}
	 	{getCirtificatemodal && <CirtificateModal closeCirtificateModal={closeCirtificateModal}  item={info} />}
		 {pageLoading ? <><div className="loader"></div></> : ''}
		 <div className="container mt-5">
    <div className="row d-flex justify-content-center profilePage">
        <div className="col-md-12">
            <div className="card p-3 py-4 profbg">
                <div className="text-center">
                  <img className="rounded-circle-half mx-width-300" src={`${REACT_APP_APIURL}/storage/${info.data_type}/${info.pic}`} alt='team1' />
                </div>
                <div className="text-center mt-3">
                    <h5 className="mt-2 mb-2">{info.name}</h5>
                    <span>City - {(type=='mentor')?info.city_name:info.city?info.city.name:''}</span>
					<div className="buttons mt-3">
                        
                        <button className="btn btn-outline-primary px-4" onClick={vieCertificate}>View Certificate</button>
                        <button className="btn btn-primary px-4 ms-3" onClick={GetInTouch}>Let's Connect</button>
                    </div>
                 </div>
             </div>
			 {/* Second Section Start */}
			 <div className="card p-3 py-4 mt-4">
                <div className="text-center mt-3">
                    <div className="px-4 mt-1">
					     <h2>About</h2>
                        <p className="text-center">{info.description} </p>
                    
                    </div>
                    
                     
                 </div>
             </div>
			 {/* End of second section */}
            
        </div>
        
    </div>
    
</div>
	</>
  )
}
