import React from 'react';
import Footer from '../Footer';

import CollUniImg from './../../../images//collUniv.jpg';
import book from './../../../images/gift/bk.jpg';
import community from './../../../images/gift/community.jpg';
import network from './../../../images/gift/network.jpg';
import graduate from './../../../images/gift/graduate.jpg';
import students from './../../../images/gift/students.jpg';
import officestud from './../../../images/gift/officestud.jpg';
import buildcar from './../../../images/gift/buildcar.jpg';
import { useEffect,useState } from 'react'
import { FaSquareFacebook,FaTwitter,FaInstagram } from "react-icons/fa6";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import http from '../../../http'

const CollegeUniv = () => {
  const [click, setClick] = useState(false)
    const handleClick = () => setClick(!click)
 const closeMenu = () => setClick(false)
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
    const [input, setInput] = useState({
      name: "",
      email:"",
      phone: "",
      message:"",
      applyfrom:'College & University',
    });
    const [errors, setErrors] = useState({
      name: "",
      email:"",
      phone: "",
      message:""
    });
    const [btnLoading, setBtnLoading] = useState(false);
  
  const handleChange=(e)=>{
    const { name, value } = e.target;
    setInput(values => ({ ...values, [name]: value }));
    validateField(name, value);
  }
  const validateField = (name, value) => {
    let error = "";
  
    switch (name) {
      case "name":
        error =
          value.trim().length >= 3
            ? ""
            : "Name must be at least 3 characters long.";
        break;
  
      case "email":
        error = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
          ? ""
          : "Enter a valid email address.";
        break;
  
      case "phone":
        error = /^\d{10}$/.test(value)
          ? ""
          : "Mobile number must be 10 digits.";
        break;
      case "message":
          error =
            value.trim().length >= 10
              ? ""
              : "Message must be at least 10 characters long.";
          break;
      default:
        break;
    }
  
    setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
  }
  const submitFinder = async (e) => {
    e.preventDefault();
    const isValid = Object.values(errors).every((err) => err === "") &&
        Object.values(input).every((value) => value.trim() !== "");
    if (isValid) {
      if (isValid) {
          setBtnLoading(true);
          await http.post('/store-otherapplicant', { ...input ,applyfrom:'College & University'})
            .then(response => {
              setBtnLoading(false);
              toast.success("Thank you for submitting ! We will revert back you soon")
              setInput('');
              
              })
            .catch(e => {
              setBtnLoading(false);
              console.log(e);
            });
        }
    }else{
      alert("Please fix the errors before submitting.");
    }
    
    
   
  }

  return(
  <>
  
  <section className="loan-bg pb-5 pt-5  position-relative loan-apply-banner secWrap">

<div className="container mb-5">

  <div className="row">
    <div className="col-lg-6">
      <h2 className="f_size_40 wt-8 text-dark l_height45  mb_50 loan-heading loan-apply-new">
      Grow your College Enrollments by 130%   <br />
        <span className="c_dark">with college Dwar.</span>
      </h2>
      <p className="ft-17 text-dark">Increase your Globle Presence & the number of qualified students.</p>
      <a href='#CollegeUniForm'><button className="btn btn-cd btn-lg mt-4" onClick={closeMenu}>Work with Us</button> </a>
    </div>
    <div className="col-lg-6  ml-auto my-auto">
    <img className="w-100" src={CollUniImg} />
    </div>
  </div>
</div>
</section>
  <div className='col-sm-12'><h3 className="mb-4 text-center">100+ Colleges are doing amazing enrollments with CD</h3></div>
  <section className='secWrap bg-cd-light'>
                <div className="container">
                    <div className='row'>
                       
                        <div className="col-lg-4">
                          <div className='d-flex align-self-center'>
                            <div className='mr-2'>
                              <img src={book} />
                            </div>
                            <div className='pl-10'>
                                <p className='m-0 ft-12'>CONVERSATION</p>
                                <h6> Quality Applications</h6>
                                <p>For every hundred students, we get fifteen more enrolled than others.</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className='d-flex align-self-center'>
                            <div className='mr-2'>
                              <img src={community} />
                            </div>
                            <div className='pl-10'>
                                <p className='m-0 ft-12'>DIVERSITY</p>
                                <h6> Unmatched Diversity</h6>
                                <p>College Dwar students come from across India.</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className='d-flex align-self-center'>
                            <div className='mr-2'>
                              <img src={network} />
                            </div>
                            <div className='pl-10'>
                                <p className='m-0 ft-12'>PRODUCTIVITY / ROI</p>
                                <h6> Less administration. More education</h6>
                                <p>Partner Colleges spend 25% more time on things that matter.</p>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>

            </section>
  <section className='secWrap bg-cd'>
<div  className="container-xxl py-5">
        <div  className="container">
            <div  className="row g-4">
            <div className='col-sm-12 text-center text-white'><h3 className="mb-4 wt-6">Our Impact</h3>
            <p>Colleges are doing amazing enrollments with
                        CD Colleges are doing amazing enrollments with CD</p>
            </div>
                <div  className="col-lg-4 col-sm-12 wow fadeInUp">
                    <div  className="service-item text-center pt-3">
                        <div  className="impact">
                           <div className='impactImg'><img src={graduate} /></div> 
                           <div><h5  className="mb-3">10000+</h5></div> 
                            <p>Students helped</p>
                        </div>
                    </div>
                </div>
                <div  className="col-lg-4 col-sm-12 wow fadeInUp">
                    <div  className="service-item text-center pt-3">
                        <div  className="impact">
                           <div className='impactImg'><img src={students} /></div> 
                           <div><h5  className="mb-3">100+</h5></div> 
                            <p>Partner colleges</p>
                        </div>
                    </div>
                </div>
                <div  className="col-lg-4 col-sm-12 wow fadeInUp">
                    <div  className="service-item text-center pt-3">
                        <div  className="impact">
                           <div className='impactImg'><img src={officestud} /></div> 
                           <div><h5  className="mb-3">98%</h5></div> 
                            <p>Success of Admission rate</p>
                        </div>
                    </div>
                </div>
               
            </div>
        </div>
    </div>
</section>
 <section className="loan-bg pb-5 pt-5  position-relative loan-apply-banner secWrap">

<div className="container mb-5">

  <div className="row">
    <div className="col-lg-6">
      <h2 className="f_size_40 wt-8 text-dark l_height45  mb_50 loan-heading loan-apply-new">
      Build brand awareness with new students  <br />
        <span className="c_dark">& families. </span>
      </h2>
      <p className="ft-17 text-dark">Bye-bye billboards and broad-reach social campaigns, and hello College Dwar! For 50 million students & families,
       College Dwar is where the search for the perfect college begins — ensure you get found with a stand-out</p>
      <h6>College Dwar Premium Profile and Sponsored Listings.</h6> 
    </div>
    <div className="col-lg-6  ml-auto my-auto">
    <img className="w-100" src={buildcar} />
    </div>
  </div>
</div>
</section>
<section className="loan-bg pb-5 pt-5  position-relative loan-apply-banner secWrap bg-cd" id="CollegeUniForm">
<div className="container mb-5">
  <div className="row">
  
      
    <div className="col-lg-6 offset-md-3 text-white">
   <div className='ft-30 wt-6'> There's a more effective way to fill your funnel and its start with College Dwar</div>
    <h6 className='ft-20 mt-4'>Book a call</h6>
      <p>to learn more about College Dwar's enrollments marketing solutions</p>
      <ToastContainer  />
    <form className="contact_form_box mt-5" >
                  <div className="row">

                    <div className="col-lg-12">
                      <div className="form-group text_box">
                        <input type="text" name="name" value={input.name || ''} onChange={handleChange} placeholder=" Name" />
                        {errors.name && <span style={{ color: "red" }}>{errors.name}</span>}
                      </div>
                    </div>

                 

                    <div className="col-lg-12">
                      <div className="form-group text_box">
                        <input type="tel" name="phone" value={input.phone || ''} onChange={handleChange} placeholder="Phone No" />
                        {errors.phone && <span style={{ color: "red" }}>{errors.phone}</span>}
                       
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group text_box">
                        <input type="email" name="email" value={input.email || ''} onChange={handleChange} placeholder="Email" />
                        {errors.email && <span style={{ color: "red" }}>{errors.email}</span>}
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group text_box">
                        <input type="text" name="message" value={input.message || ''} onChange={handleChange} placeholder="Job Title" />
                        {errors.message && <span style={{ color: "red" }}>{errors.message}</span>}
                      </div>
                    </div>                    
                    <div className="col-lg-12 text-center mt-4">
                    {
                btnLoading ? <>
                  <button class="btn btn-primary" type="button" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                  </button>
                </> : <>
                  <button type="submit" className="btn btn-primary btn-larg" onClick={submitFinder}>Submit</button>
                 
                </>
              }
                     
                  </div>
                  </div>

                </form>
    </div>
  </div>
</div>
</section>
  <Footer />
  </>
        );
    }

    export default CollegeUniv;