import React from 'react';
import Footer from '../Footer';
import onlineImg from './../../../images/onlinemba.jpg';
import rightMark40 from './../../../images/gift/rightMark30.jpg';
import BrandMba from '../sections/BrandMba';
import Testimonial from '../testimonial/TestimonialMba';
import { useState,useEffect } from 'react'
import ApplyForm from '../../modal/ApplyForm';
const OnlineMba = () => {
    const [applyFormModal, setApplyFormModal] = useState(false);
    const applyNowFun=()=>{
        setApplyFormModal(true)
    }
    useEffect(() => {
    window.scrollTo(0, 0)
    }, [])  
  return(
  <>
  {applyFormModal && <ApplyForm setApplyFormModal={setApplyFormModal} applyfrom={'Online MBA'}  />}
  <section className="homebanner secWrap">
	<div className="container">
		<div className="row">
			<div className="col-md-12">
				<div className="home-banner-head">
					<h1 className="moboff">Climb The Ladder Of<br />Success <span>With Our Online Degree</span></h1>
					<p>Online Prgrams Entitled by UGC from NAAC <br /> A+ Acredited University.</p>
					 <div className="tBtns">
						<a onClick={applyNowFun} data-toggle="modal" data-target="#applyForm" className="applyBtn bg-cd text-white">Apply Now</a>
					</div> 
					
				</div>
			</div>
		</div>
	</div>
</section>
  
    
  <BrandMba />
  <section className='secWrap'>
<div  className="container-xxl py-5">
        <div  className="container">
            <div  className="row g-4">
            <div className='col-sm-12 mb-4 text-center'>
            <h3 className="mb-0">Why Choose Online Degree with College Dwar</h3>
            
            </div>
                <div  className="col-lg-4 col-sm-12 wow fadeInUp">
                    <div  className="service-item text-left">
                        <div  className="p-4">
                            <ul className='nbpList'>
                                <li>University Grants Commission (UGC) and AICTE approved</li>
                            </ul>
                           
                        </div>
                    </div>
                </div>
                <div  className="col-lg-4 col-sm-12 wow fadeInUp">
                    <div  className="service-item text-left">
                        <div  className="p-4">
                       
                        <ul className='nbpList'>
                                <li>Learn anytime, anywhere with 24/7 access to live and recorded lectures</li>
                            </ul>
                            
                        </div>
                    </div>
                </div>
                <div  className="col-lg-4 col-sm-12 wow fadeInUp">
                    <div  className="service-item text-left">
                        <div  className="p-4">
                       
                        <ul className='nbpList'>
                                <li>Industry-endorsed curriculum focusing on essential skills</li>
                            </ul>
                            
                        </div>
                    </div>
                </div>
                <div  className="col-lg-4 col-sm-12 wow fadeInUp">
                    <div  className="service-item text-left">
                        <div  className="p-4">
                       
                        <ul className='nbpList'>
                                <li>Highly Regarded MBA degree from NAAC A+ accredited University</li>
                            </ul>
                           
                    </div>
                </div>
                </div>
                <div  className="col-lg-4 col-sm-12 wow fadeInUp">
                    <div  className="service-item text-left">
                        <div  className="p-4">
                       
                        <ul className='nbpList'>
                                <li>Learn from industry stalwarts and world-renowned academicians</li>
                            </ul>
                            
                        </div>
                    </div>
                </div>
                <div  className="col-lg-4 col-sm-12 wow fadeInUp">
                    <div  className="service-item text-left">
                        <div  className="p-4">
                        
                        <ul className='nbpList'>
                                <li>Web-Proctored examinations with the freedom to take exams anywhere</li>
                            </ul>
                            
                        </div>
                    </div>
                </div>
                <div  className="col-lg-4 col-sm-12 wow fadeInUp">
                    <div  className="service-item text-left">
                        <div  className="p-4">
                       
                        <ul className='nbpList'>
                                <li>Access to College Dwar's rich network of 1000+ alumni</li>
                            </ul>
                            
                        </div>
                    </div>
                </div>
                <div  className="col-lg-4 col-sm-12 wow fadeInUp">
                    <div  className="service-item text-left">
                        <div  className="p-4">
                        
                        <ul className='nbpList'>
                                <li>Experience a learning environment with no Back Row</li>
                            </ul>
                            
                        </div>
                    </div>
                </div>
                <div  className="col-lg-4 col-sm-12 wow fadeInUp">
                    <div  className="service-item text-left">
                        <div  className="p-4">
                        
                        <ul className='nbpList'>
                                <li>Seamless career progression with access to 500+ hiring partners</li>
                            </ul>
                           
                        </div>
                    </div>
                </div>
               
            </div>
        </div>
    </div>
</section>
  <section className="bg-cd-light">
  <Testimonial/>
  </section>
  
  <Footer />
  </>
        );
    }

    export default OnlineMba;