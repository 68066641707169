import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Routes, Route, Link } from 'react-router-dom';
import logo from "./../../images/logo.jpg";

import Home from '../backend/Home';
import Error from '../Error';
import MentorList from '../backend/mentor/MentorList';
import AddMentor from '../backend/mentor/AddMentor';
import EditMentor from '../backend/mentor/EditMentor';
import CollageList from '../backend/collage/CollageList';
import AddCollage from '../backend/collage/AddCollage';
import CourseList from '../backend/course/CourseList';
import AddCourse from '../backend/course/AddCourse';
import EditCourse from '../backend/course/EditCourse';
import Applicant from '../backend/applicant/Applicant';
import ApplicantOther from '../backend/applicant/ApplicantOther';
import AuthUser from '../AuthUser';
import EditCollage from '../backend/collage/EditCollage';

export default function Auth() {

    const {token,logout} = AuthUser();
    const logoutUser = () => {
        if(token != undefined){
            logout();
        }
    }


  return (
    <div>

<Navbar collapseOnSelect expand="lg" className="bg-white shadow-sm " sticky="top">
      <Container>
        <Navbar.Brand><Link className="nav-link" to={"/home"}>
        <h1 className='cap-primary'><span className='text-primary-color'>College</span>Dwar</h1>
        </Link></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="justify-content-end flex-grow-1 pe-3">
            <Link className="nav-link" to={"/home"}> Home</Link>
            <Link className="nav-link" to={"/mentor-list"}> Mentors</Link>
            <Link className="nav-link" to={"/college-list"}> Colleges</Link>
            <Link className="nav-link" to={"/course-list"}> Courses</Link>
            <Link className="nav-link" to={"/applicant-list"}>Applicants</Link>
            <Link className="nav-link" to={"/applicant-otherlist"}>Other Applicants</Link>
            <Link className="nav-link" onClick={logoutUser}>Logout</Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
            <div className="container mt-3">
              <Routes>

                <Route path="/home" element={<Home />} />
                <Route path="/mentor-list" element={<MentorList />} />
                <Route path="/add-mentor" element={<AddMentor />} />
                <Route path="/edit-mentor/:id" element={<EditMentor />} />
                <Route path="/college-list" element={<CollageList />} />
                <Route path="/add-collage" element={<AddCollage />} />
                <Route path="/edit-collage/:id" element={<EditCollage />} />
                <Route path="/course-list" element={<CourseList />} />
                <Route path="/add-course" element={<AddCourse />} />
                <Route path="/edit-course/:id" element={<EditCourse />} />
                <Route path="/applicant-list" element={<Applicant />} />
                <Route path="/applicant-otherlist" element={<ApplicantOther />} />
                <Route path="*" element={<Error />} />


              </Routes>

            </div>

      
    </div>
  )
}
